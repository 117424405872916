export function loadScript(src) {
  const script = document.createElement('script');
  script.async = true;
  script.src = src;
  document.head.appendChild(script);
}

{/* <script type="text/javascript" >
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(98023972, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
   });
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/98023972" style="position:absolute; left:-9999px;" alt="" /></div></noscript> */}

export function loadYandexMetrika(id) {

  return (
    "<script src='https://mc.yandex.ru/metrika/watch.js' type='text/javascript'></script>\
    <script type='text/javascript'>\
          try {\
                var yaCounter98023972 = new Ya.Metrika({\
                id:98023972,\
                clickmap:true,\
                trackLinks:true,\
                accurateTrackBounce:true,\
                webvisor:true,\
                trackHash:true\
                });\
          } catch(e) { }\
    </script>"
  );

  // const script = document.createElement('script');
  // script.async = true;
  // script.src = 'https://mc.yandex.ru/metrika/tag.js';
  // document.head.appendChild(script);
  // script.onload = () => {
  //   window.ym = window.ym || function () {
  //     (window.ym.a = window.ym.a || []).push(arguments);
  //   };
  //   window.ym.l = 1 * new Date();

  //   window.ym(id, "init", {
  //     clickmap: true,
  //     trackLinks: true,
  //     accurateTrackBounce: true
  //   });
  // };
}
