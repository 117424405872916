export default function convertPlatformsToInstant(obj) {

  const categorizedObject = Object.keys(obj).reduce((acc, key) => {
    if (key.startsWith("R-")) {
      acc.NonInstant[key] = obj[key];
    } else {
      acc.Instant[key] = obj[key];
    }
    return acc;
  }, { Instant: {}, NonInstant: {} })

  return categorizedObject
}
