import React, { useEffect, useState, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { SubmitCodesState, UserState } from '../../types/rootState';
import { Typography, Form, Select, Spin} from 'antd';
//@ts-ignore
import styles from './styles.module.scss';
import { platformGroupsFetch } from '../../reducers/submitCodes';
import useMediaQuery from '../../utils/useMediaQuery';
import convertData from '../../utils/convertData';
import convertPlatforms from '../../utils/convertPlatforms';
import SubmitForm from '../../components/SubmitForm';
import filterObject from '../../utils/filterObject';
import { useDispatch } from 'react-redux';
import NotAvailable from '../../components/NotAvailable';
import convertPlatformsToInstant from '../../utils/convertPlatformsToInstant';
import SubmitCodesForm from '../../components/SubmitCodesForm';
import { getPrimaryPlatforms } from '../../utils/getPrimaryPlatforms';
import SupportedCardsTable from '../../components/SupportedCardsTable';
import { CreditCardOutlined } from '@ant-design/icons';

const { Title } = Typography
const { Option, OptGroup } = Select;

interface IconData {
  [key: string]: string
}

export interface TableProps {
  item: [string, {
    [key: string]: [{
      [key: string]: string | number
    }]
  }],
  icons: IconData
}

const icons: IconData = {
  Airbnb: 'logo_airbnb.svg',
  Apple: 'logo_apple.svg',
  BestBuy: 'logo_bestbuy.svg',
  XBox: 'logo_xbox.svg',
  PSN: 'logo_psn.svg',
  Amazon: 'logo_amazon.svg',
  Steam: 'logo_steam.svg',
  Blizzard: 'logo_blizzard.svg',
  Spotify: 'logo_spotify.svg',
  IKEA: 'logo_ikea.svg',
  BarnesAndNoble: 'logo_barnesandnoble.svg',
  Zalando: 'logo_zalando.svg',
  Ebay: 'logo_ebay.svg',
  Hulu: 'logo_hulu.svg',
  OldNavy: 'logo_oldnavy.svg',
  KarmaKoin: 'logo_karmakoin.svg',
  Macys: 'logo_macys.svg',
  MYTOYS: 'logo_mytoys.svg',
  Nintendo: 'logo_nintendo.svg',
  Gap: 'logo_gap.svg',
  Google: 'logo_google.svg',
  Netflix: 'logo_netflix.svg',
  Starbucks: 'logo_starbucks.svg',
  Walmart: 'logo_walmart.svg',
  Hotels: 'logo_hotels.svg',
  Default: 'logo_default.svg',
}

const SubmitCodes = () => {

  const freezeData = useSelector((state) => state?.noAuthReducer?.contacts, shallowEqual)
  const freeze = freezeData?.payload?.freeze && freezeData?.payload?.freeze;

  const dispatch: any = useDispatch();

  useEffect(() => {
    try {
     const res = dispatch(platformGroupsFetch())
    }
    catch (error) {
      // console.log('ERROR', error)
    }
  }, [dispatch])

  const { payload, isLoading } = useSelector((state: SubmitCodesState) => state?.submitCodes?.platformsList) || {}

  const { balance } = useSelector((state: UserState) => state?.user) || {}

  // console.log('balance', balance)

  const currency = balance?.payload?.currency

  // console.log('currency', currency)
  const { platformGroups: groups, supportedCards: cards, showCustomRate: showRate, ngnRateMult: ngnRate, usdRates, ngnRates, countryCurrencies, codeFormatsPlatforms: instructions, codeFormatTypes } = payload || {};

  const filterGroupsWithNoData = filterObject(groups)
  const filterCardsWithNoData = filterObject(cards)

  const convertedCardList = convertData(cards)
  const convertedGroups = convertPlatforms(filterGroupsWithNoData)
  const newConvertedCardList = convertPlatforms(filterCardsWithNoData)

  const instantNonInstantList = convertPlatformsToInstant(filterCardsWithNoData)
  // console.log('filterCardsWithNoData', filterCardsWithNoData)
  // console.log('newConvertedCardList', newConvertedCardList)

  // console.log('instantNonInstantList', instantNonInstantList)

  const platformsList = convertedGroups && Object.keys(convertedGroups)
  const platformsListFiltered = newConvertedCardList && Object.keys(newConvertedCardList)

  const convCardList = convertedCardList && Object.entries(convertedCardList)
  const newConvCardList = newConvertedCardList && Object.entries(newConvertedCardList)
  const types = instantNonInstantList && Object.keys(instantNonInstantList)
  const typesToCurrencies = instantNonInstantList && Object.entries(instantNonInstantList)

  // console.log('platformsListSorted', platformsListFiltered)
  // console.log('newConvertedCardList', newConvertedCardList)
  // console.log('newConvCardList', newConvCardList)

      // Сортировка массива по заданным платформам
      const mainPlatforms = ['XBox', 'Blizzard', 'Steam', 'Amazon', 'Google', 'Netflix', 'Nintendo', 'PSN', 'Zalando'];

      const existingSpecificElements = mainPlatforms.filter(item => platformsListFiltered?.includes(item));

      // Sorting the remaining elements alphabetically
      const remainingPlatforms = platformsListFiltered?.filter(item => !mainPlatforms.includes(item)).sort();

      // Combining the existing specific elements and the remaining elements
      const sortedList = [...existingSpecificElements, ...remainingPlatforms as string[]];
      // console.log(`sorted list: ${sortedList}`)


    // const remainingPlatforms = platformsListFiltered.filter(item => !mainPlatforms.includes(item)).sort();
    // const sortedList = [...mainPlatforms, ...remainingPlatforms];

  
  // getXbox()
  //===========================================================

  const defaultPlatform = 'XBox'

  const [curPlarformValue, setCurPlarformValue] = useState('');

  const [curCardValues, setCurCardValues] = useState<string[]>([]);
  const [curRate, setCurRate] = useState('');

  // console.log('curPlarformValue', curPlarformValue)
  

  useEffect(() => {
    defaultPlatform && setCurPlarformValue(defaultPlatform)
  }, [defaultPlatform])

  const countryList = useMemo(
    () => groups && curPlarformValue && groups?.[curPlarformValue],
    [curPlarformValue, groups]
  )

  const defaultCountry = countryList && countryList[0]

  const [curCountryValue, setCurCountryValue] = useState('')
  

  useEffect(() => {
    defaultCountry && setCurCountryValue(defaultCountry)
  }, [defaultCountry])

  const media600 = useMediaQuery('(max-width: 600px)')


  // const balance = localStorage.getItem('balance')
  // const storedCurrency = balance !== null && JSON.parse(balance)?.currency

  // console.log('balanceSubmit', balance)
  // console.log('storedCurrency', storedCurrency)

  // Получаем список платформ
  // Конвертируем в Instant / Non-instant
  // NEW LOGIC WILL GO HERE

  const [selectedPlatform, setSelectedPlatform] = useState('XBox');
  const [selectedVariant, setSelectedVariant] = useState('XBox');

  const primaryPlatforms = getPrimaryPlatforms(filterCardsWithNoData);

  const instantPlatforms = primaryPlatforms.filter(platform => !platform.startsWith('R-'));
  const nonInstantPlatforms = primaryPlatforms.filter(platform => platform.startsWith('R-'));

  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
    setSelectedVariant(platform); 
    setCurCardValues([]);
    setCurRate("")
  };

  const platformVariants = selectedPlatform 
    ? Object.keys(filterCardsWithNoData).filter(variant => variant.startsWith(selectedPlatform))
    : [];

  useEffect(() => {
    defaultPlatform && setSelectedPlatform(defaultPlatform)
  }, [defaultPlatform]);


  return (
    <>
    <div className={styles.submitWrapper} style={{maxWidth: '1280px', margin: '24px auto' }}>
      
      {isLoading ? (
              <Spin style={{ margin: '25px' }} />
            ) : (
        !freeze ?
        <>
        <Title level={1}>Submit codes</Title>
        <div className={styles.supportedCardsLink}>Selection section for platforms</div>
        <div className={styles.submitCodes}>

          <div className={styles.platformsSelector}>
            {isLoading && curPlarformValue === undefined ? (
              <Spin style={{ margin: '25px' }} />
            ) : (
              <div>
                {media600 ? ( <Select
                  onChange={handlePlatformSelect}
                  defaultValue='XBox'
                  defaultOpen={false}
                  virtual={false}
                  style={{ width: '80vw', marginTop: '16px' }}
                >
                  <OptGroup label="Instant">
                    {instantPlatforms.map(platform => (
                      <Option key={platform} value={platform}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[platform] ? icons[platform] : icons['Default']}
                        alt='platform logo' />
                        {platform}
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="Non-Instant">
                    {nonInstantPlatforms.map(platform => (
                      <Option key={platform} value={platform}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[platform.slice(2)] ? icons[platform.slice(2)] : icons['Default']}
                        alt='platform logo' />
                        {platform.slice(2)}
                      </Option>
                    ))}
                  </OptGroup>
                </Select> ) : (
                  <Select
                  open
                  // placeholder="Choose a platform"
                  onChange={handlePlatformSelect}
                  defaultActiveFirstOption
                  defaultValue='XBox'
                  style={{ width: '100%' }}
                  bordered={false}
                  listHeight={650}
                  dropdownStyle={{
                       padding: '0 16px',
                       width: '220px',
                       boxShadow: 'unset',
                     }}
                >
                  <OptGroup label="Instant">
                    {instantPlatforms.map(platform => (
                      <Option key={platform} value={platform}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[platform] ? icons[platform] : icons['Default']}
                        alt='platform logo' />
                        {platform}
                      </Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="Non-Instant">
                    {nonInstantPlatforms.map(platform => (
                      <Option key={platform} value={platform}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[platform.slice(2)] ? icons[platform.slice(2)] : icons['Default']}
                        alt='platform logo' />
                        {platform === "R-Hotels" ? `${platform.slice(2)}.com` : platform.slice(2)}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
                )}




                {/* {types.map((type, index) => {
                  const typeToPlatform = Object.keys(instantNonInstantList[type])
                  return (
                  <section>
                  <h3>{type}</h3>
                  <Form.Item className={styles.selector}>
                  <Select
                     open
                     defaultValue='XBox'
                     onChange={setCurPlarformValue}
                     style={{ width: '100%' }}
                     bordered={false}
                     listHeight={650}
                     dropdownStyle={{
                       padding: '0 16px',
                       width: '220px',
                       boxShadow: 'unset',
                     }}
                  >
                    {typeToPlatform.map((platform, index) => (<Option value={platform} key={platform}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[platform.slice(2)] ? icons[platform.slice(2)] : icons['Default']}
                        alt='platform logo' />
                        {type === "NonInstant" ? platform.slice(2) : platform}
                      </Option>))}
                  </Select>
                  </Form.Item>
                  </section>
                  )
              })} */}

                {/* {media600 ? (
                  <Select
                    // defaultActiveFirstOption
                    defaultValue='XBox'
                    defaultOpen={false}
                    onChange={setCurPlarformValue}
                    virtual={false}
                  >
                    {sortedList && sortedList?.map((item: string) => (
                      <Option value={item} key={item}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[item] ? icons[item] : icons['Default']}
                        alt='platform logo' />
                        {item}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    // defaultActiveFirstOption
                    open
                    defaultValue='XBox'
                    onChange={setCurPlarformValue}
                    style={{ width: '100%' }}
                    bordered={false}
                    listHeight={650}
                    dropdownStyle={{
                      padding: '0 16px',
                      width: '220px',
                      boxShadow: 'unset',
                    }}
                  >
                    {sortedList && sortedList.map((item: string) => (
                      <Option value={item} key={item}>
                        <img
                        style={{ paddingRight: '10px', height: '16px' }}
                        src={icons[item] ? icons[item] : icons['Default']}
                        alt='logo' />
                        <span>{item}</span>
                      </Option>
                    ))}
                  </Select>
                )} */}
              {/* </Form.Item> */}
              </div>)}
          
          </div>

          <div className={styles.wrapper}>



        {selectedPlatform && (
        <>
          <SubmitCodesForm 
          filterCardsWithNoData={filterCardsWithNoData}
          selectedPlatform={selectedPlatform} 
          isLoading={isLoading}
          codeFormatTypes={codeFormatTypes}
          showRate={showRate}
          instructions={selectedPlatform.startsWith("R-") ? instructions?.[selectedPlatform.slice(2)] ? instructions?.[selectedPlatform.slice(2)] : instructions?.['Default'] : instructions?.[selectedPlatform] ? instructions?.[selectedPlatform] : instructions?.['Default']}
          currency={currency}
          usdRates={usdRates}
          countryCurrencies={countryCurrencies}
          platformVariants={platformVariants}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          curCardValues={curCardValues}
          setCurCardValues={setCurCardValues}
          curRate={curRate}
          setCurRate={setCurRate}
           />
        </>
      )}
      
        {/* {newConvCardList?.map((item: any, index) => (
              <SubmitForm
              key={index}
              item={item}
              curPlarformValue={curPlarformValue}
              isLoading={isLoading}
              showRate={showRate}
              ngnRate={ngnRate}
              usdRates={usdRates}
              ngnRates={ngnRates}
              countryCurrencies={countryCurrencies}
              instructions={instructions[curPlarformValue] ? instructions[curPlarformValue] : instructions['Default']}
              currency={currency}
              codeFormatTypes={codeFormatTypes}
              />
            ))
          } */}
          </div>

        </div></> : <div><NotAvailable /></div>
            )}
            
    </div>
    {/* <div style={{maxWidth: '1280px', margin: '24px auto' }}>
    <Title level={2}>All cards we support</Title>
      {isLoading ? (
              <Spin style={{ margin: '25px' }} />
            ) : (
      <div className={styles.supportedCards}>
        {convCardList && convCardList?.map((item: any, index) => (
          <SupportedCardsTable key={index} item={item} icons={icons}/>
      ))
      }
      </div>
      )}
      </div> */}
      </>
  )
}

export default SubmitCodes
