import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Table, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import MyCodesState from '../../types/rootState'
import { codePackFetch } from '../../reducers/myCodes'
import Tag from '../../components/Tag'
import useMediaQuery from '../../utils/useMediaQuery'
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const { Title, Text } = Typography

interface ListType {
  code: string
  status: string
  reward: string
  rewardUsd: string
  rewardPercent: string
  codeValue: string
  notes: string
  error: string
}

const Code = () => {

  // const location = useLocation();
  // const query = qs.parse(location.pathname.replace(/^\?/, ''));
  // const id = query.id as string

  const location = useLocation();
  const pathnameParts = location.pathname.split('/');
  const id = pathnameParts[pathnameParts.length - 1];

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(codePackFetch({ packId: id }))
  }, [dispatch, id])

  const { payload, isLoading } = useSelector((state: MyCodesState) => state?.myCodes?.codePack) || {}

  const { list } = useMemo(
    () => ({
      list: (payload && payload.list) || [],
    }),
    [payload]
  )

  const media580 = useMediaQuery('(max-width: 580px)')

  function GetColumns(): ColumnsType<ListType> {
    return [
      {
        key: '1',
        dataIndex: 'code',
        title: 'CODE',
        align: 'center',
        width: 270,
        className: 'ellipsis',
        render: (_, record) => <span>{record.code}</span>
      },
      {
        key: '2',
        dataIndex: 'status',
        title: 'STATUS',
        align: 'center',
        width: 150,
        render: (status) => {
          if (status === 'Error') {
            return <Tag color="red" text={status} />
          } else if (status === 'Done') {
            return <Tag color="green" text={status} />
          } else {
            return <Tag text={status} />
          }
        },
      },
      {
        key: '3',
        dataIndex: 'reward',
        title: 'REWARD',
        align: 'center',
        width: 120,
        onCell: (record): any => {
          if (record.status === 'Error') {
            return { colSpan: 3 }
          }
        },
        render: (reward, record) => (record.status === 'Error' ? record.error : reward),
      },
      {
        key: '4',
        dataIndex: 'rewardUsd',
        title: 'REWARD USD',
        align: 'center',
        width: 150,
        onCell: (record): any => {
          if (record.status === 'Error') {
            return { colSpan: 0 }
          }
        },
      },
      {
        key: '5',
        dataIndex: 'rewardPercent',
        title: 'REWARD %',
        align: 'center',
        width: 120,
        onCell: (record): any => {
          if (record.status === 'Error') {
            return { colSpan: 0 }
          }
        },
      },
      {
        key: '6',
        dataIndex: 'codeValue',
        title: 'CODE VALUE',
        align: 'center',
        width: 150,
        onCell: (record): any => {
          if (record.status === 'Error') {
            return { colSpan: 0 }
          }
        },
      },
      // {
      //   key: '6',
      //   dataIndex: 'notes',
      //   title: 'NOTES',
      //   align: 'center',
      //   width: 200,
      //   onCell: (record): any => {
      //     if (record.status === 'Error') {
      //       return { colSpan: 0 }
      //     }
      //   },
      // },
    ]
  }

  function GetXSMobileColumns(): ColumnsType<ListType> {
    return [
      {
        key: '1',
        dataIndex: 'code',
        title: 'CODE',
        // align: 'center',
        width: 180,
        className: 'codePackMobile',
          render: (status, record) => {
          return (
          <>
          {record.code}
          {/* <br/> */}
          
           {
           (record.status === 'Error') ? (
             <Tag color="red" text={record.status} />
            ) : (record.status === 'Done') ? (
             <Tag color="green" text={record.status} />
             ) : (
             <Tag text={record.status} />
             )
           }
           {/* <br/> */}
           {record.codeValue || 'n/d'}
           {/* <br />
           {record.notes && record.notes.length > 30 ? <Tooltip placement="topLeft" title={record.notes}><small>{record.notes.slice(0, 35)}...</small></Tooltip> : <small>{record.notes}</small>} */}
          </>
        )},
      },
      {
        key: '3',
        dataIndex: 'reward',
        title: 'REWARD',
        align: 'center',
        width: 140,
        className: 'codePackMobileText',
        onCell: (record): any => {
          if (record.status === 'Error') {
            return { colSpan: 1 }
          }
        },
        render: (reward, record) => (record.status === 'Error' ? (record.error.length > 30 ?  <Tooltip placement="topLeft" title={record.error}><small>{record.error.slice(0, 35)}...</small></Tooltip> : <small>{record.error}</small>) : <b>{reward} / {record.rewardUsd}</b>),
      },
    ]
  }

  const [columns, setColumns] = useState(GetColumns())

  useEffect(() => {
    if (media580) setColumns(GetXSMobileColumns())
    else setColumns(GetColumns())
  }, [list, media580])

  return (
    <div style={media580 ? {maxWidth: '960px', margin: '36px auto'} : {width: '85vw', maxWidth: '1280px', margin: '24px auto'}}>
      <Title>Code Pack Details</Title>
      <Table
        // bordered
        className="codePackTable"
        rowKey={(record) => record.code}
        dataSource={list}
        pagination={false}
        columns={columns}
        loading={isLoading}
        // scroll={{ y: '', x: 'max-content' }}
        summary={(pageData) => {
          let totalReward = 0
          let totalRewardUsd = 0
          let totalCodeValue = 0
          let currency = ''

          const parseData = (data: any) => {
            const parsed = parseFloat(data)
            if (isNaN(parsed)) return 0
            return parsed
          }


          pageData.forEach(({ reward, rewardUsd, codeValue }) => {
            const parsedReward = parseData(reward)
            const parsedRewardUsd = parseData(rewardUsd)
            const parsedCodeValue = parseData(codeValue)
            
            
            currency = reward.slice(-3)
            totalReward += parsedReward
            totalRewardUsd += parsedRewardUsd
            totalCodeValue += parsedCodeValue
          })
          return (
            <>
            {!media580 ? (
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} align="right">
                  Total reward:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                  <Text strong>{totalReward.toFixed(2)} {currency}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  Total reward USD:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center">
                  <Text strong>{totalRewardUsd.toFixed(2)} USD</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                Total code value:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="center">
                  <Text strong>{totalCodeValue.toFixed(2)} {currency}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
          ) : (
            <>
            <Table.Summary.Row>
                <Table.Summary.Cell index={1} align="left">
                  Total reward:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                  <Text strong>{totalReward.toFixed(2)} {currency}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={3} align="left">
                  Total reward USD:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="center">
                  <Text strong>{totalRewardUsd.toFixed(2)} USD</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={5} align="left">
                  Total code value:
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} align="center">
                  <Text strong>{totalCodeValue.toFixed(2)} {currency}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              </>
          )
              }
            </>
          )
        }}
      />
    </div>
  )
}

export default Code
